@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

.editor_listing_table {
    @apply w-full p-2 m-1;
}
body { -webkit-print-color-adjust: exact; }
@media print{
  @page {
      size: A4 portrait;
      margin:1cm;
  }
  ::-webkit-scrollbar {
      display: none;
  }
}
.paginate_button {
  color: black;
  float: left;
  padding: 4px 16px;
  text-decoration: none;
}
.current{
  background-color: #A7B778;
  color: white; 
}
.paging_simple_numbers{
  display: flex;
  justify-content: end;
}
.dataTables_filter{
  display: flex;
  justify-content: end;
}
.dataTables_filter input{
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(167 183 120 / var(--tw-border-opacity));
  border-width: 1px;
  border-radius: 0.375rem;
  height: 1.5rem;
  padding-left: 0.5rem;
  margin-left:0.6rem ;
}
